import React, { useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// components
import LandingPage from "../../components/JobFair/LandingPage"

const BREAKPOINT = 1060

const JobFairPage = () => {
  const [windowWidth, setWindowWidth] = useState(0)
  const isMobile = windowWidth < BREAKPOINT

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return (
    <Layout>
      <SEO title="Job Fair" />
      <LandingPage isMobile={isMobile} />
    </Layout>
  )
}

export default JobFairPage
