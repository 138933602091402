import React from "react"
import PropTypes from "prop-types"
import TitleHeading from "../TitleHeading"
import styled from "styled-components"

const MEDIUM_URL = "https://medium.com/compfest/"

const PillBlogStyle = styled.div`
  cursor: pointer;
  position: relative;
  background: #212121;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  margin: 15px 0 0 0;

  .blog__thumb {
    border-radius: 10px 0 0 10px;
    background-size: cover;
    background-position: center;
    min-width: 83px;
  }

  .blog__desc {
    padding: 0 15px;
  }

  @media screen and (min-width: 762px) {
    background: none;

    .blog__desc {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .blog__thumb {
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 180px;
    }

    .blog__thumb:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(
        180deg,
        rgba(19, 19, 19, 0) 0%,
        rgba(19, 19, 19, 0.7) 100%
      );
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }
`
const PillBlog = ({ title, tag, imgUrl, uniqueSlug }) => {
  return (
    <PillBlogStyle
      onClick={() => (window.location.href = `${MEDIUM_URL}${uniqueSlug}`)}
    >
      <div
        className="blog__thumb"
        style={{
          backgroundImage: `url(https://miro.medium.com/max/400/${imgUrl})`,
        }}
      />
      <div className="blog__desc">
        <TitleHeading heading={title} subheading={tag} size="s" />
      </div>
    </PillBlogStyle>
  )
}

PillBlog.propTypes = {
  title: PropTypes.string,
  tag: PropTypes.string,
  imgUrl: PropTypes.string,
  uniqueSlug: PropTypes.string,
}

export default PillBlog
