import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import MainBlog from "../../LandingPage/PostMedium/BlogPost/main"
import PillBlog from "../../LandingPage/PostMedium/BlogPost/pill"

const CSS = styled.div`
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .post__mediumcontainer {
    &:last-child {
      > div:last-child {
        margin-top: 1rem;
      }
    }

    flex-basis: 57%;
  }

  .post__pillposts {
    > div {
      margin-top: 1rem;
    }

    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    .post__mediumcontainer {
      &:first-child {
        margin-right: 1rem;

        .post__pillposts {
          margin-top: 1rem;
        }
      }

      &:last-child {
        .post__pillposts {
          margin-bottom: 1rem;
        }

        > div:last-child {
          margin-top: 0;
        }
      }

      margin-top: 0;
      display: flex;
      flex-direction: column;
    }

    .post__pillposts {
      > div {
        margin-top: 0;
      }

      > div:first-child {
        margin-right: 1rem;
      }

      flex-direction: row;
    }

    .post__pillposts > * {
      flex-basis: 50%;
    }
  }
`

const PostMedium = ({ isMobile }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMediumPost(sort: { fields: createdAt, order: DESC }, limit: 6) {
          nodes {
            title
            uniqueSlug
            virtuals {
              previewImage {
                imageId
              }
              tags {
                name
              }
            }
          }
        }
      }
    `
  )
  const posts = data.allMediumPost.nodes

  return (
    <CSS>
      <div className="post__mediumcontainer">
        <MainBlog
          title={posts[0].title}
          tag={posts[0].virtuals.tags[0] && posts[0].virtuals.tags[0].name}
          imgUrl={posts[0].virtuals.previewImage.imageId}
          uniqueSlug={posts[0].uniqueSlug}
        />
        <div className="post__pillposts">
          {posts
            .slice(1, 3)
            .map((post, idx) =>
              isMobile ? (
                <MainBlog
                  key={idx}
                  title={post.title}
                  tag={post.virtuals.tags[0] && post.virtuals.tags[0].name}
                  imgUrl={post.virtuals.previewImage.imageId}
                  uniqueSlug={post.uniqueSlug}
                />
              ) : (
                <PillBlog
                  key={idx}
                  title={post.title}
                  uniqueSlug={post.uniqueSlug}
                  tag={post.virtuals.tags[0] && post.virtuals.tags[0].name}
                  imgUrl={post.virtuals.previewImage.imageId}
                />
              )
            )}
        </div>
      </div>
      <div className="post__mediumcontainer">
        <div className="post__pillposts">
          {posts
            .slice(3, 5)
            .map((post, idx) =>
              isMobile ? (
                <MainBlog
                  key={idx}
                  title={post.title}
                  tag={post.virtuals.tags[0] && post.virtuals.tags[0].name}
                  imgUrl={post.virtuals.previewImage.imageId}
                  uniqueSlug={post.uniqueSlug}
                />
              ) : (
                <PillBlog
                  key={idx}
                  title={post.title}
                  uniqueSlug={post.uniqueSlug}
                  tag={post.virtuals.tags[0] && post.virtuals.tags[0].name}
                  imgUrl={post.virtuals.previewImage.imageId}
                />
              )
            )}
        </div>
        <MainBlog
          title={posts[5].title}
          tag={posts[5].virtuals.tags[5] && posts[5].virtuals.tags[5].name}
          imgUrl={posts[5].virtuals.previewImage.imageId}
          uniqueSlug={posts[5].uniqueSlug}
        />
      </div>
    </CSS>
  )
}

PostMedium.propTypes = {
  isMobile: PropTypes.bool,
}

export default PostMedium
