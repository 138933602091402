import React from "react"
import styled from "styled-components"
const TitleHeadingStyle = styled.div`
  > * {
    margin: 0;
  }

  padding: 15px 0px;

  .titleheading__subheading {
    font-weight: normal;
    font-size: ${(props) => (props.size === "s" ? "10px" : "12px")};
    line-height: 16px;
    font-family: "DM Sans";
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .titlheading__heading {
    font-weight: bold;
    font-size: ${(props) => (props.size === "s" ? "14px" : "18px")};
    line-height: 140%;
    max-width: ${(props) => (props.size === "s" ? "initial" : "406px")};
  }

  @media screen and (min-width: 992px) {
    .titleheading__subheading {
      font-size: ${(props) => (props.size === "s" ? "12px" : "18px")};
      margin-bottom: ${(props) => (props.size === "s" ? "6px" : "10px")};
    }

    .titlheading__heading {
      font-size: ${(props) => (props.size === "s" ? "14px" : "24px")};
    }
  }
`

const TitleHeading = ({ heading, subheading, size }) => {
  return (
    <TitleHeadingStyle size={size}>
      <h3 className="titleheading__subheading">{subheading}</h3>
      <h1 className="titlheading__heading">{heading}</h1>
    </TitleHeadingStyle>
  )
}

export default TitleHeading
