import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Formik, Form, Field } from "formik"
import { navigate, graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import { useDispatch } from "react-redux"

// images
import Search from "../../../../assets/images/jobfair-assets/search.svg"
import MainIcon from "../../../../assets/images/jobfair-assets/landing-main-icon.svg"

// components
import Button from "../../Button"

// redux
import { setCustomKeyword } from "../../../redux/slices/jobfair"

const BREAKPOINT = 1060

const CSS = styled.div`
  .jumbotron {
    background: black;
    color: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5.1875rem 7.125rem 12.5rem 7.125rem;
    box-sizing: border-box;

    .content {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .left {
        h1 {
          font-size: 2.25rem;
          font-weight: 600;
          background: -webkit-linear-gradient(135deg, #fe6616 0%, #ffb23e 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 1.25rem;
        }

        form {
          background: #212121;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-sizing: border-box;
          border-radius: 0.5rem;
          width: 39.6875rem;
          padding: 0.5rem;
          display: flex;
          justify-content: space-between;

          div.input {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
          }
        }

        img {
          height: 1.093125rem;
          margin: 0 1.70375rem 0 0.953125rem;
        }

        input {
          border: none;
          outline: none;
          width: 100%;
          height: 100%;
          background: none;
          font-size: 1.5rem;
          opacity: 1;

          &::placeholder {
            opacity: 0.6;
          }
          &::placeholder,
          & {
            color: #f3f3f3;
            font-weight: 500;
            font-size: 1.125rem;
          }
        }

        button {
          font-family: "DM Sans";
          font-weight: bold;
        }
      }

      .right {
        img {
          width: 29.625rem;
        }
      }
    }

    .popular-search-bar {
      display: flex;
      margin-top: 0.875rem;
      max-width: 39.6875rem;

      h3 {
        color: white;
        font-size: 0.875rem;
        font-weight: normal;
        padding: 0.25rem 0;
        margin: 0.375rem 0;
      }

      .popular-searches {
        display: flex;
        flex-wrap: wrap;
        width: max-content;
      }

      .popular-search-item {
        box-sizing: border-box;
        margin: 0.375rem;

        h4 {
          &:hover {
            background: #2c2c2c;
            border-color: #2c2c2c;
          }

          color: #f3f3f3;
          font-weight: normal;
          font-size: 0.875rem;
          border: 1px solid #595959;
          box-sizing: border-box;
          padding: 0.25rem 0.75rem;
          border-radius: 0.25rem;
          white-space: nowrap;
        }
      }
    }
  }

  @media (max-width: 1240px) {
    .jumbotron {
      .content {
        .right {
          display: none;
        }
      }
    }
  }

  @media (max-width: 1060px) {
    .jumbotron {
      padding: 2rem 0 1rem;
      box-sizing: border-box;

      .content {
        .left {
          width: 100%;
          h1 {
            height: max-content;
          }

          form {
            background: none;
            flex-direction: column;
            align-items: center;
            padding: 0;
            width: 100%;

            div,
            button {
              width: 100%;
            }

            div.input {
              background: #212121;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 0.5rem;
              padding: 1rem 1rem 1rem 0;
              display: flex;
              margin-bottom: 1.25rem;
            }

            img {
              margin: 0 1.20375rem;
            }
          }
        }

        .right,
        .popular-search-bar {
          display: none;
        }
      }
    }
  }
`

const Jumbotron = () => {
  const dispatch = useDispatch()

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulPopularSearchJobFair {
          popularKeywords: nodes {
            keyword
          }
        }
      }
    `
  )
  const { popularKeywords } = data.allContentfulPopularSearchJobFair
  const [windowWidth, setWindowWidth] = useState(0)
  const isMobile = windowWidth < BREAKPOINT
  const intl = useIntl()
  const { locale } = useLocalization()

  const resetCustomKeyword = () => {
    dispatch(setCustomKeyword(null))
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)

    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  const PopularSearchBar = () => (
    <div className="popular-search-bar">
      <h3>
        <FormattedMessage id="jobfair.index.popular" />:
      </h3>
      <div className="popular-searches">
        {popularKeywords.slice(0, 4).map(({ keyword }, idx) => (
          <Link
            className="popular-search-item"
            to={`/job-fair/search?search=${keyword}`}
            onClick={resetCustomKeyword}
            key={idx}
          >
            <h4>{keyword}</h4>
          </Link>
        ))}
      </div>
    </div>
  )

  return (
    <CSS>
      <div className={`jumbotron ${isMobile ? "mobile" : ""}`}>
        <div className="content">
          <div className="left">
            <h1>
              <FormattedMessage id="jobfair.index.discoverTech" />
            </h1>
            <Formik
              initialValues={{ keyword: "" }}
              onSubmit={(values) => {
                resetCustomKeyword()
                navigate(
                  `${locale === "id" ? "/id" : ""}/job-fair/search?search=${
                    values.keyword
                  }`
                )
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="input">
                    <img src={Search} alt="" />
                    <Field
                      type="text"
                      name="keyword"
                      placeholder={intl.formatMessage({
                        id: "jobfair.index.searchPlaceholder",
                      })}
                    />
                  </div>
                  <Button type="submit">
                    <FormattedMessage id="jobfair.searchButton" />
                  </Button>
                </Form>
              )}
            </Formik>
            <PopularSearchBar />
          </div>
          <div className="right">
            <img src={MainIcon} alt="" />
          </div>
        </div>
      </div>
    </CSS>
  )
}

export default Jumbotron
