import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import { FormattedMessage } from "react-intl"

// images
import Briefcase from "../../../../assets/images/jobfair-assets/briefcase.svg"
import LocationIcon from "../../../../assets/images/jobfair-assets/location.svg"
import BookmarkMobile from "../../../../assets/images/jobfair-assets/bookmark.svg"
import BookmarkDesktop from "../../../../assets/images/jobfair-assets/bookmark-desktop.svg"
import BookmarkDesktopSaved from "../../../../assets/images/jobfair-assets/bookmark-saved.svg"
import BookmarkMobileSaved from "../../../../assets/images/jobfair-assets/bookmark-mobile-saved.svg"

// redux
import { useDispatch, useSelector } from "react-redux"
import { isLoggedIn } from "../../../redux/slices/session/selectors"
import { toastActions } from "../../../redux/toast"
import { addSavedJob, removeSavedJob } from "../../../redux/slices/jobs"

// utils
// import { handleNullErrorString } from '../utils/funcs'

const CSS = styled.div`
  .job-item h1,
  .job-item h2,
  .job-item h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .job-item.mobile {
    padding: 1rem;

    .left {
      display: none;
    }

    .right {
      margin: 0;

      .header.mobile {
        align-items: center;

        .left {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-start;

          .img-container {
            height: 2.5rem;
            width: 2.5rem;
            margin-right: 1rem;

            img {
              width: 2rem;
              height: auto;
            }
          }
        }

        .bookmark {
          width: 0.875rem;
          height: auto;
          cursor: pointer;

          &.checked {
          }
        }
      }

      h1.job-title {
        font-size: 1.25rem;
      }

      .footer {
        h1 {
          font-size: 0.875rem;
        }
      }

      .bottom {
        .right {
          display: none;
        }
      }
    }
  }

  .job-item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background: #1d1d1d;
    border-radius: 0.625rem;
    padding: 2.25rem 1.5rem;
    margin: 0.5rem 0 1rem 0;

    &:hover {
      .save-btn {
        opacity: 1 !important;
        transition: opacity 0.2s;
      }
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      .img-container {
        height: 4.375rem;
        width: 4.375rem;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 0.5rem;

        img {
          width: 3.5rem;
        }
      }
    }

    .right {
      margin-left: 2rem;
      width: 100%;

      .header {
        display: flex;
        justify-content: space-between;

        h2 {
          font-size: 0.875rem;
          font-weight: normal;
          height: 1.25rem;
        }
      }

      & * {
        font-family: "DM Sans";
      }

      h1.job-title {
        font-family: "itc";
        font-size: 1.5rem;
        margin: 0.5625rem 0 0.5rem 0;
      }

      .footer {
        display: flex;
        flex-wrap: wrap;

        & div:first-child {
          margin-right: 3.25rem;
        }

        div {
          display: flex;
          align-items: center;

          img {
            height: 0.625rem;
          }

          h1 {
            font-size: 1rem;
            font-weight: normal;
            margin-left: 0.625rem;
            height: 1.375rem;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;

        .left {
          display: block;
          width: 100%;
        }

        .right {
          width: max-content;
          margin: 0;
          display: flex;
          align-items: flex-end;

          .save-btn {
            border: 2px solid #fdd262;
            box-sizing: border-box;
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.15));
            border-radius: 2.875rem;
            display: flex;
            align-items: center;
            padding: 0.8125rem 1.6875rem;
            opacity: 0;
            cursor: pointer;

            &.saved {
              background: #fdd262;

              h3 {
                color: #131313;
              }
            }

            img {
              height: 1.125rem;
            }

            h3 {
              margin-left: 0.8125rem;
              height: 1.375rem;
              font-family: DM Sans;
              font-style: normal;
              font-weight: bold;
              font-size: 1rem;
              line-height: 140%;
              color: #fdd262;
            }
          }
        }
      }
    }
  }
`

export const LOCATION_LENGTH_LIMIT = 30

const JobItem = ({
  companyName,
  icon,
  jobTitle,
  employmentType,
  location,
  category,
  isMobile,
  slug,
  id: backendId,
  companyNameLimit,
  jobTitleLimit,
  applyDate,
  showSaveBtn,
}) => {
  const companyNameLength = companyNameLimit || 47
  const jobTitleNameLength = jobTitleLimit || 46
  const date = new Date(applyDate)
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  const { locale } = useLocalization()
  const formattedDate = date.toLocaleDateString(
    locale === "id" ? "id-ID" : "en-UK",
    options
  )

  return (
    <CSS>
      <div className={`job-item ${isMobile ? "mobile" : ""}`}>
        <Link className="left" to={`/job/${slug}`}>
          <div className="img-container">
            <img src={icon} alt="" />
          </div>
        </Link>
        <div className="right">
          {!isMobile ? (
            <Link to={`/job/${slug}`} className="header">
              <h2>
                {companyName.length > companyNameLength
                  ? `${companyName.substr(0, companyNameLength)}...`
                  : companyName}
              </h2>
              <h2>{category}</h2>
            </Link>
          ) : (
            <div className="header mobile">
              <Link className="left" to={`/job/${slug}`}>
                <div className="img-container">
                  <img src={icon} alt="" />
                </div>
                <h2>
                  {companyName.length > companyNameLength
                    ? `${companyName.substr(0, companyNameLength)}...`
                    : companyName}
                </h2>
              </Link>
              <JobItem.SaveBtn backendId={backendId} isMobile />
            </div>
          )}
          <div className="bottom">
            <Link className="left" to={`/job/${slug}`}>
              <h1 className="job-title">
                {jobTitle.length > jobTitleNameLength
                  ? `${jobTitle.substr(0, jobTitleNameLength)}...`
                  : jobTitle}
              </h1>
              <div className="footer">
                <div>
                  <img src={Briefcase} alt="" />
                  <h1>{employmentType}</h1>
                </div>
                <div>
                  <img src={LocationIcon} alt="" />
                  <h1>
                    {location.length > LOCATION_LENGTH_LIMIT
                      ? `${location.substr(0, LOCATION_LENGTH_LIMIT)}...`
                      : location}
                  </h1>
                </div>
              </div>
              {isMobile && applyDate && (
                <div style={{ marginTop: 15 }}>
                  <p>
                    <FormattedMessage id="jobfair.applied.appliedOn" />:
                  </p>
                  <p>
                    <strong>{formattedDate}</strong>
                  </p>
                </div>
              )}
            </Link>
            <div className="right">
              {applyDate ? (
                <div style={{ textAlign: "right", alignSelf: "start" }}>
                  <p>
                    <FormattedMessage id="jobfair.applied.appliedOn" />:
                  </p>
                  <p>
                    <strong>{formattedDate}</strong>
                  </p>
                </div>
              ) : (
                <JobItem.SaveBtn backendId={backendId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </CSS>
  )
}

JobItem.SaveBtn = ({ backendId, isMobile }) => {
  const [saved, setSaved] = useState(false)

  const dispatch = useDispatch()

  const { jobs } = useSelector((state) => state.jobs)
  const loggedIn = useSelector(isLoggedIn)

  useEffect(() => {
    loggedIn && setSaved(jobSaved(backendId))
  })

  const jobSaved = (openingId) =>
    getApplicationId(openingId) !== null
      ? jobs[getApplicationId(openingId)].saved
      : false

  const getApplicationId = (openingId) => {
    let id = null
    Object.values(jobs).map((job) => {
      if (job.openingId === openingId) {
        id = job.id
      }
    })
    return id
  }

  const handleClick = () => {
    if (loggedIn) {
      setSaved((prev) => !prev)
      const savedState = jobSaved(backendId)
      dispatch(
        savedState
          ? removeSavedJob(getApplicationId(backendId))
          : addSavedJob(backendId)
      )
      setSaved(jobSaved(backendId))
    } else {
      toastActions.showErrorToast("You need to login before saving a job")
    }
  }

  return !isMobile ? (
    <div className={`save-btn ${saved ? "saved" : ""}`} onClick={handleClick}>
      <img src={saved ? BookmarkDesktopSaved : BookmarkDesktop} alt="" />
      <h3>{`${saved ? "Saved" : "Save"}`}</h3>
    </div>
  ) : (
    <img
      onClick={handleClick}
      className="bookmark"
      src={saved ? BookmarkMobileSaved : BookmarkMobile}
      alt=""
    />
  )
}

JobItem.propTypes = {
  companyName: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  employmentType: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  companyNameLimit: PropTypes.number,
  jobTitleLimit: PropTypes.number,
  applyDate: PropTypes.string,
  showSaveBtn: PropTypes.bool,
}

JobItem.SaveBtn.propTypes = {
  backendId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
}

export default JobItem
