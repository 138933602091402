import styled from "styled-components"

const JobFairCSS = styled.div`
  main.mobile {
    padding: 0 1rem;
    .company-bar {
      margin-top: 6rem;
      margin-bottom: 6rem;

      h1 {
        font-size: 0.75rem;
      }

      .companies {
        padding: 0;
        img {
          margin: 1rem;
        }
      }
    }

    .it-industry {
      padding: 6rem 0 0 0;
      margin-bottom: 6rem;

      .item-container {
        padding: 0;
        grid-template-columns: 50% 50%;
        grid-row-gap: 2rem;
        grid-column-gap: 1rem;
      }
    }

    .jobs {
      padding: 0;
      .jobs-list {
        flex-direction: column;
      }
    }

    .content-container {
      padding: 0;
    }

    .jobfair-footer {
      .top {
        flex-direction: column;

        > .mascot:first-child {
          margin: 0;
          margin-bottom: 2rem;
          height: 13.1875rem;
        }

        > .mascot:last-child {
          display: none;
        }

        .mid {
          justify-content: flex-start;

          h1,
          p {
            text-align: left;
          }

          h1 {
            font-size: 1.5rem;
            height: 2.125rem;
            width: 100%;
          }

          p {
            width: 100%;
          }

          a,
          button {
            width: 100%;
          }
        }
      }

      .bottom {
        h1 {
          height: max-content;
        }
      }
    }

    .content-container {
      .button {
        margin: 1rem 0 4.5rem 0;
      }
    }
  }

  main {
    & * {
      font-family: "itc";
      margin: 0;
    }

    .toggle-language {
      padding-top: 1.5rem !important;
    }

    button {
      outline: none;
    }

    .company-bar {
      box-sizing: border-box;
      margin-bottom: 12.5rem;

      h1 {
        text-align: center;
        font-weight: 500;
        font-size: 1.125rem;
        height: 1.5625rem;
      }

      .sponsors,
      .non-sponsors {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .sponsors {
        img {
          margin: 2rem 1.6875rem;
          height: 3.75em;
        }
      }

      .non-sponsors {
        img {
          margin: 2rem 1.6875rem;
          height: 2.5em;
        }
      }

      .companies {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 7.125rem;
        box-sizing: border-box;
      }
    }

    .it-industry {
      padding-top: 12.5rem;
      margin-bottom: 12.5rem;

      .button {
        display: none !important;
      }

      .item-container {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        grid-row-gap: 3.6875rem;

        .it-industry-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: max-content;
          justify-self: center;
          align-self: end;

          img {
            height: 8.640625rem;
            width: auto;
            margin-bottom: 1.25rem;
          }

          h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
            height: 1.5625rem;
            width: 12em;
            max-width: 260px;

            @media (max-width: 768px) {
              max-width: 210px;
            }
          }
        }
      }
    }

    .desktop .job-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #1d1d1d;
      border-radius: 1em;
      padding: 1rem;
      width: 18.1875rem;
      height: 13.5rem;
      margin: 0.75rem 0.5rem;
      box-sizing: border-box;

      .right {
        height: 100%;

        .header {
          display: flex;
          align-items: center;

          h1 {
            font-weight: 500;
            font-size: 1em;
            margin-left: 1em;
          }

          .icon-container {
            background: white;
            border-radius: 0.5rem;
            height: 2.75rem;
            width: 2.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;

            img {
              width: 1.65rem;
            }
          }
        }

        .bottom {
          height: calc(100% - 2.75rem);

          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;

            .job-title {
              font-size: 1.5em;
              margin: auto 0;
            }

            .footer {
              display: flex;
              justify-content: space-between;
              margin: 0;

              div {
                display: flex;
                h1 {
                  margin-left: 1em;
                  font-size: 0.875em;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }

    .jobs {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 7.125rem;
      .jobs-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;

        @media (min-width: 1500px) {
          display: grid;
          grid-template-columns: min-content min-content min-content min-content;
        }
      }
    }

    .jobfair-footer {
      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7.5rem;
        align-items: center;
        position: relative;

        .mascot {
          height: 18.25rem;
          width: auto;

          &:first-child {
            margin-left: -5em;
          }

          &:last-child {
            margin-right: -5em;
          }
        }

        .mid {
          display: flex;
          flex-direction: column;
          align-items: center;
          h1 {
            text-align: center;
            font-size: 2.25rem;
            height: 3.125rem;
            font-weight: 600;
            background: -webkit-linear-gradient(
              135deg,
              #fe6616 0%,
              #ffb23e 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 1.25rem;
          }
          p {
            text-align: center;
            font-size: 1rem;
            font-weight: normal;
            line-height: 140%;
            color: #f3f3f3;
            margin-bottom: 2rem;
            width: 28.625rem;
          }

          button {
            width: 16.9375rem;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            img {
              height: 1.093125rem;
            }

            h3 {
              font-weight: 500;
              font-size: 1rem;
              line-height: 140%;
              text-align: center;
              margin-left: 0.70375rem;
            }
          }
        }
      }
      .bottom {
        margin-bottom: 11.25rem;
        & h1:nth-child(2) {
          font-weight: bold;
        }
        h1 {
          text-align: center;
          font-weight: normal;
          height: 1.375rem;
          font-size: 1rem;
        }
      }
    }

    .content-container {
      padding: 0 7.125rem;
      .title {
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 3rem;
      }

      .button {
        display: flex;
        justify-content: center;
        margin: 2.75rem 0 12.5rem 0;

        button {
          border-color: white;
          color: white;
        }
      }
    }

    .contact__header {
      background-image: linear-gradient(
        135deg,
        #279686 0%,
        #fdd262 100%
      ) !important;
    }
  }

  @media (max-width: 360px) {
    main {
      .it-industry {
        .item-container {
          .it-industry-item {
            img {
              height: 8rem;
              width: auto;
            }
          }
        }
      }
    }
  }

  @media (max-width: 320px) {
    main {
      .it-industry {
        .item-container {
          .it-industry-item {
            h1 {
              font-size: 1rem;
            }
            img {
              height: 7.5rem;
              width: auto;
            }
          }
        }
      }
    }
  }
`

export default JobFairCSS
