import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "react-intl"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

// styling
import JobFairCSS from "./JobFairCSS"

// components
import Jumbotron from "./Jumbotron"
import { Button } from "jasper-ui"
import PostMedium from "../PostMedium"
import JobItem from "../JobItem"
import SponsorMedpar from "../../SponsorMedpar"
import { ContactSection } from "../../../pages/playground/about"
import ToggleLanguage from "../../ToggleLanguage"

import { useDispatch, useSelector } from "react-redux"
import { isLoggedIn } from "../../../redux/slices/session/selectors"
import { fetchJobs } from "../../../redux/slices/jobs"

// images
import SearchIcon from "../../../../assets/images/jobfair-assets/search.svg"
import FooterMascotLeft from "../../../../assets/images/jobfair-assets/footer-mascot-left.svg"
import FooterMascotRight from "../../../../assets/images/jobfair-assets/footer-mascot-right.svg"

// it industry icons
import SoftwareEngineering from "../../../../assets/images/jobfair-assets/it-industry/software-engineering.svg"
import ProductDesign from "../../../../assets/images/jobfair-assets/it-industry/product-designer.svg"
import GameDevelopment from "../../../../assets/images/jobfair-assets/it-industry/game-development.svg"
import WebDevelopment from "../../../../assets/images/jobfair-assets/it-industry/web-development.svg"
import MobileDevelopment from "../../../../assets/images/jobfair-assets/it-industry/mobile-development.svg"
import QualityAssurance from "../../../../assets/images/jobfair-assets/it-industry/quality-assurance.svg"
import ItBusiness from "../../../../assets/images/jobfair-assets/it-industry/it-business.svg"
import DataAnalyst from "../../../../assets/images/jobfair-assets/it-industry/data-analysis.svg"

// redux
import { setCustomKeyword } from "../../../redux/slices/jobfair"

const itIndustryData = [
  {
    name: "Software Engineering",
    kategori: "Software Engineer",
    keyword:
      "Technical & Quality Assurance, Software QA Engineer, Game Back-End Programmer, Game Front-End Programmer, Web Developer, Internship Web Developer, Android Developer, Senior Android Engineer, Lead Android Engineer, Back-End Programmer, Back-End Engineer, Senior Back-End Engineer, Front-End Engineer, Senior Front-End Engineer, Software Engineer, Application Developer, Junior Programmer (Full Stack), Senior Data and Market Analyst, Senior Product Manager, Data Engineer, Technical Agent Support, Activation Provisioning Process",
    image: SoftwareEngineering,
  },
  {
    name: "Product Design",
    kategori: "Product Design",
    keyword:
      "UX Researcher, UX Designer, Technical & Quality Assurance, Software QA Engineer, Front-End Engineer, Senior Front-End Engineer, Senior Data and Market Analyst, Business Analyst, Product Manager, Senior Product Manager, Data Engineer, Technical Agent Support, Activation Provisioning Process",
    image: ProductDesign,
  },
  {
    name: "Game Development",
    kategori: "Game Development",
    keyword: "Game Back-End Programmer, Game Front-End Programmer",
    image: GameDevelopment,
  },
  {
    name: "Web Development",
    kategori: "Web Development",
    keyword:
      "Web Developer, Internship Web Developer, Back-End Engineer, Back-End Programmer, UX Researcher, UX Designer, Senior Back-End Engineer, Front-End Engineer, Senior Front-End Engineer, Software Engineer, Application Developer, Junior Programmer (Full Stack)",
    image: WebDevelopment,
  },
  {
    name: "Mobile Development",
    kategori: "Mobile Development",
    keyword:
      "Android Developer, Senior Android Engineer, Lead Android Engineer, Software Engineer, Application Developer, Activation Provisioning Process",
    image: MobileDevelopment,
  },
  {
    name: "Technical & Quality Assurance",
    kategori: "Technical & Quality Assurance",
    keyword:
      "Technical & Quality Assurance, Software QA Engineer, Technical Agent Support, Activation Provisioning Process",
    image: QualityAssurance,
  },
  {
    name: "IT Business",
    kategori: "IT Business",
    keyword:
      "Senior Data and Market Analyst, Business Analyst, Product Manager, Senior Product Manager, Data Engineer, Technical Agent Support, Activation Provisioning Process, Technical & Quality Assurance",
    image: ItBusiness,
  },
  {
    name: "Data & Analyst",
    kategori: "Data & Analyst",
    keyword:
      "Data Engineer, Senior Data and Market Analyst, Business Analyst, Product Manager, Senior Product Manager",
    image: DataAnalyst,
  },
]

const sponsorNames = ["mitratel", "shopee", "dewaweb", "bni"]

const JobFair = ({ isMobile }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulJobOpening(sort: { order: ASC, fields: createdAt }) {
          nodes {
            nama
            backendId
            slug
            lokasi
            perusahaan {
              logo {
                fluid {
                  src
                }
              }
              nama
              kategori
            }
            employmentType
            slug
            jobCategory {
              kategori
            }
          }
          locationList: distinct(field: lokasi)
          jobList: distinct(field: nama)
          employmentTypeList: distinct(field: employmentType)
          industryList: distinct(field: perusahaan___kategori)
        }

        allContentfulPerusahaan {
          companyNames: distinct(field: nama)
          logoPanjangList: nodes {
            slug
            nama
            logoPanjang {
              fluid {
                src
              }
            }
          }
        }
      }
    `
  )

  const dispatch = useDispatch()
  const loggedIn = useSelector(isLoggedIn)
  useEffect(() => {
    loggedIn && dispatch(fetchJobs())
  }, [])

  const numberOfCompanies = data.allContentfulPerusahaan.companyNames.length
  const jobData = data.allContentfulJobOpening.nodes
  const { logoPanjangList } = data.allContentfulPerusahaan

  const getSponsorLogos = () => {
    const arr = []
    logoPanjangList.map((obj) => {
      if (sponsorNames.includes(obj.slug.toLocaleLowerCase())) {
        arr.push(obj)
      }
    })
    return arr
  }

  const getNonSponsorLogos = () => {
    const arr = []
    logoPanjangList.map((obj) => {
      if (!sponsorNames.includes(obj.slug.toLocaleLowerCase())) {
        arr.push(obj)
      }
    })
    return arr
  }

  const CompanyBar = () => (
    <div className="company-bar">
      <h1>
        <FormattedMessage
          id="jobfair.index.overXCompaniesHiring"
          values={{ numberOfCompanies }}
        />
      </h1>
      <div className="companies">
        <div className="sponsors">
          {getSponsorLogos().map(
            ({ logoPanjang, slug }, idx) =>
              logoPanjang && (
                <Link to={`/job-fair/${slug}`} key={idx}>
                  <img src={logoPanjang.fluid.src} alt="" />
                </Link>
              )
          )}
        </div>
        <div className="non-sponsors">
          {getNonSponsorLogos().map(
            ({ logoPanjang, slug }, idx) =>
              logoPanjang && (
                <Link to={`/job-fair/${slug}`} key={idx}>
                  <img src={logoPanjang.fluid.src} alt="" />
                </Link>
              )
          )}
        </div>
      </div>
    </div>
  )

  const ContentContainer = ({
    title,
    link,
    buttonContent,
    classname,
    children,
    normalLink,
  }) => (
    <div className={`content-container ${classname || ""}`}>
      <h1 className="title">{title && <FormattedMessage id={title} />}</h1>
      {children}
      {link && (
        <div className="button">
          {normalLink ? (
            <a href={link}>
              <Button className="navbar-btn secondary">
                {buttonContent && <FormattedMessage id={buttonContent} />}
              </Button>
            </a>
          ) : (
            <Link to={link}>
              <Button className="navbar-btn secondary">
                {buttonContent && <FormattedMessage id={buttonContent} />}
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  )

  const Footer = () => {
    return (
      <div className="jobfair-footer">
        <div className="top">
          <img className="mascot" src={FooterMascotLeft} alt="" />
          <div className="mid">
            <h1>
              <FormattedMessage id="jobfair.index.applyBefore" />
            </h1>
            <p>
              <FormattedMessage id="jobfair.index.applyBefore.description" />
            </p>
            <Link to="/job-fair/search">
              <Button>
                <div>
                  <img src={SearchIcon} alt="" />
                  <h3>
                    <FormattedMessage id="jobfair.index.applyBefore.button" />
                  </h3>
                </div>
              </Button>
            </Link>
          </div>
          <img className="mascot" src={FooterMascotRight} alt="" />
        </div>
        <div className="bottom">
          <ContactSection>
            <h2 className="about__header">
              <FormattedMessage id="contactPerson" />
            </h2>
            <div className="contact__wrapper">
              <div className="contact__items">
                <h2 className="contact__header">Muhammad Rifqi</h2>
                <h2 className="contact__text">082211133499</h2>
              </div>
              <div className="contact__items">
                <h2 className="contact__header">Hanifah Rahmajati</h2>
                <h2 className="contact__text">087887665730</h2>
              </div>
            </div>
          </ContactSection>
        </div>
      </div>
    )
  }

  return (
    <JobFairCSS>
      <main className={isMobile ? "mobile" : ""}>
        <div className="content-container toggle-language">
          <ToggleLanguage />
        </div>
        <Jumbotron />
        <CompanyBar />
        <ContentContainer classname="it-industry">
          <div className="item-container">
            {itIndustryData.map((item, idx) => (
              <JobFair.ITIndustryItem
                key={idx}
                title={item.name}
                keywords={item.keyword}
                image={item.image}
                kategori={item.kategori}
                clickAction={() => dispatch(setCustomKeyword(item.kategori))}
              />
            ))}
          </div>
        </ContentContainer>
        <ContentContainer
          title="jobfair.index.jobsAvaliable"
          classname="jobs"
          buttonContent="jobfair.index.discoverMoreJobs"
          link="/job-fair/search"
        >
          <div className="jobs-list">
            {jobData.slice(0, 8).map((job, idx) => (
              <div className={`${isMobile ? "mobile" : "desktop"}`} key={idx}>
                <JobItem
                  companyName={job.perusahaan ? job.perusahaan.nama : ""}
                  icon={
                    job.perusahaan
                      ? job.perusahaan.logo.fluid
                        ? job.perusahaan.logo.fluid.src
                        : ""
                      : ""
                  }
                  jobTitle={job.nama}
                  employmentType={job.employmentType}
                  location={job.lokasi}
                  category={job.jobCategory ? job.jobCategory[0].kategori : ""}
                  key={idx}
                  isMobile
                  slug={job.slug}
                  id={job.backendId}
                  companyNameLimit={!isMobile ? 35 : null}
                  jobTitleLimit={!isMobile ? 28 : null}
                />
              </div>
            ))}
          </div>
        </ContentContainer>
        <ContentContainer
          title="jobfair.index.articles"
          buttonContent="jobfair.index.seeAllArticles"
          link="https://medium.com/compfest"
          normalLink
        >
          <PostMedium isMobile={isMobile} />
        </ContentContainer>
        <Footer />
      </main>
      <div className="layout">
        <SponsorMedpar />
      </div>
    </JobFairCSS>
  )
}

JobFair.ITIndustryItem = ({
  title,
  keywords,
  image,
  clickAction,
  kategori,
}) => (
  <Link
    className="it-industry-item"
    to={`/job-fair/search?search=${kategori.replace("&", "%26")}`}
    onClick={clickAction}
  >
    <img src={image} alt="" />
    <h1>{title}</h1>
  </Link>
)

export default JobFair
